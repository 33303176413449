import { template as template_eb9f3ad3b26f449b869fc0622ebf094c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_eb9f3ad3b26f449b869fc0622ebf094c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
