import { template as template_e18a247a77934387a7ee97bf568fe835 } from "@ember/template-compiler";
const WelcomeHeader = template_e18a247a77934387a7ee97bf568fe835(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
