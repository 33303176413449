import { template as template_1520ce68ce464437b6b1a120d35e2369 } from "@ember/template-compiler";
const FKText = template_1520ce68ce464437b6b1a120d35e2369(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
