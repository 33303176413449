import { template as template_8ea90d8d2a0e48e4a7452d34539c1cf3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8ea90d8d2a0e48e4a7452d34539c1cf3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
